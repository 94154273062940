<template>
  <div class="chat-container" v-if="messageList!=null && messageList.length>0">
    <div class="chat-messages" ref="chatMessages">
      <div :class="{'message-hostaway': message.isIncoming, 'message-user': !message.isIncoming}"
           v-for="(message, index) in messageList" :key="index">
       <template v-if="!message.isIncoming">
         <div class="message-card-user">
           <p class="message-text">{{ removeHtmlTags(message.body) }}</p>
           <p class="message-date">{{ (message.date) }}</p>
         </div>
       </template>
       <template v-else>

         <div class="message-card-client">
           <p class="message-text">{{ removeHtmlTags(message.body) }}</p>
           <p class="message-date">{{ (message.date) }}</p>
         </div>
       </template>
        <!-- Display other message properties as needed -->
      </div>
    </div>
  </div>
  <div v-else class="mt-0">
    <div class="text-center p-5">
      <div class="no-conversation">
        <i class="fas fa-stop mx-1 mt-4" style="color: #e75634"></i>{{ $t("noconversationyet") }}
      </div>
    </div>
  </div>
</template>


<script>
import {mapActions} from "vuex";

export default {
  name: "HostawayChatComponent",
  props: ['contractId', 'reservationId'],
  watch: {
    contractId: {
      handler() {
        this.refresh();
      },
      immediate: true
    }, reservationId: {
      handler() {
        this.refresh();
      },
      immediate: true
    }
  },
  data: () => ({
    loading: true,
    refreshIntervalId: null ,
    newMessage: {body: '',reservationId: ''},
    hostawayMessage: {},
    messageList: [],
  }),
  mounted() {
    this.scrollToBottom();
    this.startRefresh(); // Start the refresh interval when the component is mounted
  },
  beforeDestroy() {
    this.stopRefresh(); // Clear the interval when the component is destroyed
  },

  methods: {
    ...mapActions('hostaway', ['getMessagesByReservationId']),
    removeHtmlTags(text) {
      const div = document.createElement('div');
      div.innerHTML = text;
      return div.textContent || div.innerText || ''; // Extracting text content without HTML tags
    },
    startRefresh() {
      // Execute refresh every 10 seconds (10000 milliseconds)
      this.refreshIntervalId = setInterval(() => {
        this.refresh();
      }, 10000);
    },
    stopRefresh() {
      // Clear the interval to stop refreshing
      clearInterval(this.refreshIntervalId);
    },

    getMessageClasses(message, isCrm = false) {
      return {
        'message-container': true,
        'hostaway-message': !isCrm && message.isIncoming,
        'crm-message': isCrm || message.source === 'crm'
      };
    },
    scrollToBottom() {
      // Scroll to the bottom of the chat messages container
      this.$refs.chatMessages.scrollTop = this.$refs.chatMessages.scrollHeight;
    },
   /* sendMessage() {
      if (this.newMessage.body.trim() !== '') {
        // Add logic to send the new message
        const newMessage = {
          body: this.newMessage.body.trim(),
          reservationId: this.reservationId,
        };
        this.sendHostawayMessage(newMessage).then(this.onMessagesRetrieved);

      }
    },*/
    refresh() {
      if (this.contractId == null) {
        return;
      }
      this.loading = true;
      this.getMessagesByReservationId({contractId:this.contractId}).then(this.onMessagesRetrieved);
    },
    onMessagesRetrieved(data) {
      this.hostawayMessage = data["messages"];
      if (this.hostawayMessage == null) {
        this.messageList = [];
        this.loading = false;
        return;
      }
      this.messageList = this.hostawayMessage.result;
      this.newMessage= {body: '',reservationId: ''};
      this.loading = false;
    },

    formatDate(date) {
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // Ensure 24-hour format
      };

      const formattedDate = new Date(date).toLocaleString('de-AT', options);
      return formattedDate.replace(',', ''); // Remove comma (optional)
    }

  }
}
</script>

<style scoped>
/* Style for the chat messages container */
.chat-messages {
  display: flex;
  flex-direction: column;
  max-height: 535px;
  overflow-y: auto;
  padding: 10px;
}

/* Style for the message cards */
.message-card-user {
  background-color: rgba(7, 94, 84, 0.58);
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
}
.message-card-client {
  background-color: #FEA47F;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  color: #000000;
}

/* Style for hostaway messages */
.message-hostaway {
  align-self: flex-start;
}

/* Style for user messages */
.message-user {
  align-self: flex-end;
}

.message-text {
  font-weight: bold;
  font-size: 14px;
}

.message-date {
  font-size: 9px;

}
.no-conversation {
  border: 2px dashed #e75634; /* Change color and thickness for the dashed border */
  padding: 0px 20px 20px;
  margin-top: 0px;
  display: inline-block; /* Ensures the border fits the content */
  border-radius: 10px; /* Optional: Add border-radius for rounded corners */
  background-color: #f8f8f8; /* Optional: Set a background color */
}
</style>
