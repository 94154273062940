<template>
  <div class="real-estate-rent-contract px-1">
    <b-overlay :show="contract == null" rounded="sm" opacity="0.9" variant="white"
               style="min-height: 100vh;margin-top: -20px">
      <div v-if="contract != null">
        <b-card class="mt-2 text-center">
          <h4 class="text-center">{{ $t("revision.revision") }}</h4>
          <p>
            <span
                v-if="permissions.allowOwnerRejectContract === true || permissions.allowOwnerApproveContract === true">{{
                $t("revision.askReview")
              }}</span>
            <br/>
            <span>{{ $t("revision.explanation") }}</span>
          </p>
          <div class="text-center">
            <b-button size="sm" variant="outline-warning" v-b-modal="`modal-reject-contract`" style="min-width: 250px"
                      v-if="permissions.allowOwnerRejectContract === true">
              <i class="fas fa-hand-paper mr-1"></i>
              <span>{{ $t("revision.rejectContract") }}</span>
            </b-button>
            <b-button size="sm" variant="outline-success" class="mx-1" @click="onContractApprove"
                      style="min-width: 250px" v-if="permissions.allowOwnerApproveContract === true">
              <i class="fas fa-check mr-1"></i>
              <span>{{ $t("revision.approveContract") }}</span>
            </b-button>
            <b-button size="sm" variant="outline-danger" v-b-modal="`modal-cancel-contract`" style="min-width: 250px"
                      v-if="permissions.allowOwnerRejectContract === true">
              <i class="fas fa-hand-paper mr-1"></i>
              <span>{{ $t("revision.cancelContract") }}</span>
            </b-button>
          </div>
        </b-card>
        <b-row>
          <!--Property-->
          <b-col xl="6" sm="12" class="mb-3 pr-1">
            <b-card style="min-height: 450px" class="h-100">
              <b-card-title class="text-center mt-1 text-bold" style="font-size: 16px;font-weight: bold">
                <i class="far fa-paper-plane mx-1" style="color: #f3a152"></i>
                {{ $t('property.property') }}
              </b-card-title>
              <div>

                <div v-if="property != null">
                  <div>
                    <b-img :src="imageSrc"/>
                    <div>
                      <b-table-simple>
                        <tr role="row">
                          <b-td><i class=" text-primary icon fas fa-map-marker-alt"></i> {{
                              $t("address.address")
                            }}:
                          </b-td>
                          <b-td>{{ property.address }} {{ property.house }}, {{ property.city }},
                            {{ property.country }}
                          </b-td>
                        </tr>

                        <tr v-if="contract!=null&&contract.bnbStyleRent!=null" role="row">
                          <b-td><i class="text-primary icon fas fa-money-check-alt"></i>
                            <span>{{ $t('totalCharge') }}:</span>
                          </b-td>
                          <b-td>{{ convertToEURO(contract.bnbStyleRent.totalPayment) }}</b-td>
                        </tr>
                        <tr v-if="contract!=null&&contract.bnbStyleRent!=null" role="row">
                          <b-td><i class="text-primary icon fas fa-clock"></i>
                            <span class="text-capitalize">{{ $t('Start') }}:</span>
                          </b-td>
                          <b-td>{{ $moment(contract.bnbStyleRent.start).format('DD-MM-YYYY') }}</b-td>
                        </tr>
                        <tr v-if="contract!=null&&contract.bnbStyleRent!=null" role="row">
                          <b-td><i class="text-primary icon fas fa-clock"></i>
                            <span class="text-capitalize">{{ $t('End') }}:</span>
                          </b-td>
                          <b-td>{{ $moment(contract.bnbStyleRent.end).format('DD-MM-YYYY') }}</b-td>
                        </tr>
                        <tr v-if="contract!=null&&contract.bnbStyleRent!=null" role="row">
                          <b-td><i class="text-primary icon fas fa-moon"></i>
                            <span class="text-capitalize">{{ $t('Nights') }}:</span>
                          </b-td>
                          <b-td>{{ contract.bnbStyleRent.totalNights }}</b-td>
                        </tr>
                      </b-table-simple>
                    </div>
                  </div>
                </div>
                <div v-else></div>
              </div>

            </b-card>
          </b-col>
          <!--Client-->
          <b-col xl="6" sm="12" class="mb-3 pr-1">
            <b-card style="min-height: 450px" class="h-100">
              <b-card-title class="text-center mt-1 text-bold" style="font-size: 16px;font-weight: bold">
                <i class="far fa-paper-plane mx-1" style="color: #f3a152"></i>
                {{ $t('revision.client') }}
              </b-card-title>
              <div>
                <div v-if="contract != null">
                  <app-collapse accordion type="margin">
                    <app-collapse-item title="Profile - Client details for the contract" is-visible>
                      <b-table-simple responsive>
                        <b-tr>
                          <b-td><i class="icon text-primary  fas fa-user"></i> {{ $t("account.firstname") }}:</b-td>
                          <b-td class="text-capitalize">
                            <b-input-group size="sm">
                              <span class="form-data">{{ contract.client.firstname }}</span>
                            </b-input-group>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon text-primary  fas fa-user"></i> {{ $t("account.lastname") }}:</b-td>
                          <b-td class="text-capitalize">
                            <b-input-group size="sm">
                              <span class="form-data">{{ contract.client.lastname }}</span>
                            </b-input-group>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon text-primary  fas fa-birthday-cake"></i> {{
                              $t("account.birthday")
                            }}:
                          </b-td>
                          <b-td>
                                <span class="form-data" v-if="contract.client.birthday != null">{{
                                    $moment(contract.client.birthday).format($t("dateFormat.dateTime.format"))
                                  }}</span>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon text-primary  fas fa-at"></i> {{ $t("account.email") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <span class="form-data">{{ contract.client.email }}</span>
                            </b-input-group>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon text-primary  fas fa-phone"></i> {{ $t("account.phone") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <span class="form-data">{{ contract.client.phone }}</span>
                            </b-input-group>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon text-primary  fas fa-university"></i> {{ $t("address.street") }}:
                          </b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <span class="form-data">{{ contract.client.address.street }}</span>
                            </b-input-group>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon text-primary  fas fa-university"></i> {{ $t("address.city") }}:
                          </b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <span class="form-data">{{ contract.client.address.city }}</span>
                            </b-input-group>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon text-primary  fas fa-university"></i> {{ $t("address.zip") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <span class="form-data">{{ contract.client.address.zip }}</span>
                            </b-input-group>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon text-primary  fas fa-university"></i> {{ $t("address.country") }}:
                          </b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <span class="form-data">{{ contract.client.address.country }}</span>
                            </b-input-group>
                          </b-td>
                        </b-tr>

                      </b-table-simple>
                    </app-collapse-item>
                    <app-collapse-item v-bind:title="$t('revision.contract.income')">
                      <div>
                        <b-table-simple responsive>
                          <b-tr>
                            <b-td><i class="icon text-primary  fas fa-suitcase"></i> {{ $t("income.jobTitle") }}:
                            </b-td>
                            <b-td>
                              <b-input-group size="sm">
                                <span class="form-data">{{ contract.income.jobTitle }}</span>
                              </b-input-group>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td><i class="icon text-primary  fas fa-university"></i> {{
                                $t("income.companyName")
                              }}:
                            </b-td>
                            <b-td>
                              <b-input-group size="sm">
                                <span class="form-data">{{ contract.income.companyName }}</span>
                              </b-input-group>
                            </b-td>
                          </b-tr>


                          <b-tr>
                            <b-td><i class="icon text-primary  fas fa-university"></i>
                              {{ $t("income.companyAddress") }}:
                            </b-td>
                            <b-td>
                              <b-input-group size="sm">
                                <span class="form-data">{{ contract.income.companyAddress.street }}</span>
                              </b-input-group>
                            </b-td>
                          </b-tr>

                          <b-tr>
                            <b-td><i class="icon text-primary  fas fa-university"></i> {{ $t("address.city") }}:
                            </b-td>
                            <b-td>
                              <b-input-group size="sm">
                                <span class="form-data">{{ contract.income.companyAddress.city }}</span>
                              </b-input-group>
                            </b-td>
                          </b-tr>

                          <b-tr>
                            <b-td><i class="icon text-primary  fas fa-university"></i> {{ $t("address.country") }}:
                            </b-td>
                            <b-td>
                              <b-input-group size="sm">
                                <span class="form-data">{{ contract.income.companyAddress.country }}</span>
                              </b-input-group>
                            </b-td>
                          </b-tr>

                          <b-tr>
                            <b-td><i class="icon text-primary  fas fa-file-invoice"></i> {{
                                $t("income.netIncome")
                              }}:
                            </b-td>
                            <b-td>
                              <b-input-group size="sm">
                                <span class="form-data">{{ contract.income.net }}</span>
                              </b-input-group>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td><i class="icon text-primary  fas fa-user-clock"></i>
                              {{ $t("income.incomeSeniority") }}:
                            </b-td>
                            <b-td>
                              <b-input-group size="sm">
                                <span class="form-data">{{ contract.income.seniority }}</span>
                              </b-input-group>
                            </b-td>
                          </b-tr>
                        </b-table-simple>
                      </div>
                    </app-collapse-item>
                    <app-collapse-item :title="'KSV'">
                      <div>

                        <b-table-simple responsive>
                          <b-tr>
                            <b-td><i class="icon text-primary  fas fa-file-invoice"></i> {{ $t("Inkasso open") }}:
                            </b-td>
                            <b-td>
                              <b-form-checkbox
                                  id="checkbox-1"
                                  v-model="ksv.inkasso"
                                  name="checkbox-1"
                                  :value="true"
                                  :unchecked-value="false"
                                  disabled

                              >
                              </b-form-checkbox>

                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td><i class="icon text-primary  fas fa-user-clock"></i> {{ $t("Insolvency") }}:
                            </b-td>
                            <b-td>
                              <b-form-checkbox
                                  id="checkbox-2"
                                  v-model="ksv.insolvency"
                                  name="checkbox-2"
                                  :value="true"
                                  disabled
                                  :unchecked-value="false"
                              >
                              </b-form-checkbox>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td><i class="icon text-primary  fas fa-money-check-alt"></i>
                              {{ $t("Commercial Functions") }}:
                            </b-td>
                            <b-td>
                              <b-input-group size="sm">
                                <b-form-input readonly v-model="ksv.commercialFunctions"></b-form-input>
                              </b-input-group>
                            </b-td>
                          </b-tr>

                          <b-tr>
                            <b-td><i class="icon text-primary  fas fa-money-check-alt"></i> {{
                                $t("Land register")
                              }}:
                            </b-td>
                            <b-td>
                              <b-input-group size="sm">
                                <b-form-input readonly v-model="ksv.landRegister"></b-form-input>
                              </b-input-group>
                            </b-td>
                          </b-tr>

                          <b-tr>
                            <b-td><i class="icon text-primary  fas fa-money-check-alt"></i> {{ $t("Open loans") }}:
                            </b-td>
                            <b-td>
                              <b-input-group size="sm">
                                <b-form-input readonly v-model="ksv.openLoans"></b-form-input>
                              </b-input-group>
                            </b-td>
                          </b-tr>

                          <b-tr>
                            <b-td><i class="icon text-primary  fas fa-money-check-alt"></i>
                              {{ $t("Company participations") }}:
                            </b-td>
                            <b-td>
                              <b-input-group size="sm">
                                <b-form-input readonly v-model="ksv.companyParticipations"></b-form-input>
                              </b-input-group>
                            </b-td>
                          </b-tr>

                          <b-tr>
                            <b-td><i class="icon text-primary  fas fa-money-check-alt"></i> {{ $t("KSV Score") }}:
                            </b-td>
                            <b-td>
                              <b-input-group size="sm">
                                <b-form-input readonly v-model="ksv.ksvScore"></b-form-input>
                              </b-input-group>
                            </b-td>

                          </b-tr>

                          <b-td>

                            <template v-if="ksv.status==null ||ksv.status==='NOT APPLIED YET'">
                              <b-button style="white-space: nowrap" variant="outline-warning" size="sm"
                                        @click="createOrFetchKsvData">
                                {{ $t("Apply for KSV data (6€/Request)") }}
                              </b-button>
                            </template>
                            <template v-else-if="ksv.status==='APPLICATIONCREATED'">
                              <b-button disabled style="white-space: nowrap" variant="outline-warning" size="sm">
                                {{ $t("Successfully applied please wait") }}
                              </b-button>
                            </template>
                            <template v-else>
                              <b-button disabled style="white-space: nowrap" variant="outline-warning" size="sm">
                                {{ $t("Data fetched successfully") }}
                              </b-button>
                            </template>
                          </b-td>

                        </b-table-simple>
                      </div>
                    </app-collapse-item>

                  </app-collapse>
                </div>
              </div>
            </b-card>
          </b-col>
          <!--HOSTAWAY CHAT-->
          <b-col xl="6" sm="12" class="mb-3 pr-1">
            <b-card style="min-height: 450px" class="h-100">
              <b-card-title class="text-center mt-1 text-bold" style="font-size: 16px;font-weight: bold">
                <i class="far fa-paper-plane mx-1" style="color: #f3a152"></i>
                {{ $t(contract.bnbStyleRent.platform) + ' CHAT' }}
              </b-card-title>
              <div>
                <hostaway-chat-component v-bind:contractId="id" v-bind:reservation-id="contract.hostawayReservationId"/>
              </div>
            </b-card>
          </b-col>

          <b-col xl="6" sm="12" class="mb-3">
            <payment-client-table :payments="contract.clientPayments"/>
          </b-col>

          <!--          &lt;!&ndash;Time line&ndash;&gt;
                    <b-col cols="12" xl="6" class="pb-1">
                      <div class="h-100 pb-2">
                        <app-collapse accordion>
                          <app-collapse-item class="h-100" is-visible rounded ref="add-status-histories">
                            <template slot="header">
                              <div class="py-1">
                                <div class="d-flex">
                                  <feather-icon icon="ClockIcon" size="19"/>
                                  <h4 class="mb-0 ml-50 mb-0">
                                    {{ $t('timeline').toUpperCase() }}
                                  </h4>
                                </div>
                              </div>
                            </template>
                            <div style="min-height: 350px">
                              <div class="mt-2 position-relative" v-if="contract != null">
                                <perfect-scrollbar class="position-relative" :options="{maxScrollbarLength:100}">
                                  <app-timeline v-for="(statusHistory,index) in contract.statusHistories" v-bind:key="index">
                                    <app-timeline-item class="mb-2"
                                                       :title="statusHistory.status"
                                                       :time="statusHistory.time | moment('DD/MM/YY HH:mm:ss')"
                                                       :variant="statusData(statusHistory.status).variant"
                                    >
                                      <template v-slot:subtitle>
                                        <p class="m-0">{{ statusHistory.note }}</p>
                                      </template>
                                    </app-timeline-item>
                                  </app-timeline>
                                </perfect-scrollbar>
                              </div>
                            </div>
                          </app-collapse-item>
                        </app-collapse>
                      </div>
                    </b-col>-->
          <!--
                    &lt;!&ndash;Guarantors&ndash;&gt;
                    <b-col cols="12" xl="6" class="pb-1">
                      <div class="h-100 pb-2">
                        <app-collapse accordion>
                          <app-collapse-item class="h-100" is-visible rounded>
                            <template slot="header">
                              <div class="py-1 d-flex justify-content-between align-items-center">
                                <div class="d-flex">
                                  <feather-icon icon="UserIcon" size="19"/>
                                  <h4 class="mb-0 ml-50">
                                    {{ $t('revision.contract.guarantors').toUpperCase() }}
                                  </h4>
                                </div>
                              </div>
                            </template>
                            <div>
                              <div class="mt-2 position-relative" v-if="contract != null">
                                <b-tabs>
                                  &lt;!&ndash;Valid guarantors&ndash;&gt;
                                  <template v-if="Array.isArray(contract.guarantors)">
                                    <b-tab v-for="(guarantor,index) in contract.guarantors"
                                           v-bind:key="`${index}-${guarantor.email}`">
                                      <template #title>
                                        <i class="far fa-user-circle"></i>
                                        <span class="text-capitalize">{{ guarantor.firstname }} {{ guarantor.lastname }}</span>
                                      </template>
                                      <div>

                                        <b-table-simple class="my-0" responsive>
                                          <b-tr>
                                            <b-td><i class="icon text-primary  fas fa-user"></i> {{ $t("account.name") }}</b-td>
                                            <b-td class="text-capitalize">
                                              {{ guarantor.firstname }} {{ guarantor.lastname }}
                                            </b-td>
                                          </b-tr>
                                          <b-tr>
                                            <b-td><i class="icon text-primary  fas fa-at"></i> {{ $t("account.email") }}</b-td>
                                            <b-td>{{ guarantor.email }}</b-td>
                                          </b-tr>
                                          <b-tr>
                                            <b-td><i class="icon text-primary  fas fa-bookmark"></i> {{ $t("account.note") }}
                                            </b-td>
                                            <b-td>
                                              {{ guarantor.note }}
                                            </b-td>
                                          </b-tr>
                                        </b-table-simple>
                                      </div>
                                    </b-tab>
                                  </template>
                                  &lt;!&ndash;duming data&ndash;&gt;
                                  <b-tab v-for="i in (4-(contract.guarantors || []).length)" v-bind:key="i" disabled>
                                    <template #title>
                                      <i class="far fa-times-circle"></i>
                                      <span>{{ $t('None') }}</span>
                                    </template>
                                  </b-tab>
                                  &lt;!&ndash; New Tab Button&ndash;&gt;
                                  &lt;!&ndash;                  <template #tabs-end>
                                                      <b-nav-item role="presentation" href="#" v-if="(contract.guarantors || []).length < 4">
                                                        <i class="fas fa-user-plus"></i><span class="text-capitalize">{{$t("add")}}</span>
                                                      </b-nav-item>
                                                    </template>&ndash;&gt;

                                </b-tabs>
                              </div>
                            </div>
                          </app-collapse-item>
                        </app-collapse>
                      </div>
                    </b-col>
                    &lt;!&ndash;Tenant&ndash;&gt;
                    <b-col cols="12" xl="6" class="pb-1">
                      <div class="h-100 pb-2">
                        <app-collapse accordion>
                          <app-collapse-item class="h-100" is-visible rounded>
                            <template slot="header">
                              <div class="card-header d-flex justify-content-between align-items-center">
                                <div class="d-flex">
                                  <feather-icon icon="UserIcon" size="19"/>
                                  <h4 class="mb-0 ml-50">
                                    {{ $t('rent.tenant').toUpperCase() }}
                                  </h4>
                                </div>
                              </div>
                            </template>
                            <div>
                              <div class="mt-2 position-relative" v-if="contract != null">
                                <b-tabs>
                                  <template v-if="Array.isArray(contract.tenant)">
                                    <b-tab v-for="(tenant,index) in contract.tenant" v-bind:key="`${index}-${tenant.email}`">
                                      <template #title>
                                        <i class="far fa-user-circle"></i>
                                        <span class="text-capitalize">{{ tenant.firstname }} {{ tenant.lastname }}</span>
                                      </template>
                                      <div>
                                        <b-table-simple class="my-0" responsive>
                                          <b-tr>
                                            <b-td><i class="icon text-primary  fas fa-user"></i> {{ $t("account.name") }}</b-td>
                                            <b-td class="text-capitalize">{{ tenant.firstname }} {{ tenant.lastname }}</b-td>
                                          </b-tr>
                                          <b-tr>
                                            <b-td><i class="icon text-primary  fas fa-at"></i> {{ $t("account.email") }}</b-td>
                                            <b-td>{{ tenant.email }}</b-td>
                                          </b-tr>
                                          <b-tr>
                                            <b-td><i class="icon text-primary  fas fa-bookmark"></i> {{ $t("account.note") }}
                                            </b-td>
                                            <b-td>
                                              {{ tenant.note }}
                                            </b-td>
                                          </b-tr>
                                        </b-table-simple>
                                      </div>
                                    </b-tab>
                                  </template>
                                  <b-tab v-for="i in (4-(contract.tenant || []).length)" v-bind:key="i" disabled>
                                    <template #title>
                                      <i class="far fa-times-circle"></i>
                                      <span>{{ $t('None') }}</span>
                                    </template>
                                  </b-tab>
                                </b-tabs>
                              </div>
                            </div>
                          </app-collapse-item>
                        </app-collapse>
                      </div>
                    </b-col>
          -->


          <!--Files-->
          <b-col v-if="contract != null" sm="12" lg="12" xl="12" class="contract-files">
            <b-card class="rounded style4" no-body
                    style="min-height: 350px">
              <b-card-title class="text-center mt-2 text-bold" style="font-size: 16px;font-weight: bold">
                <i class="far fa-file-archive mx-1" style="color: #f3a152"></i>
                {{ $t('files.files') }}
                <div class="py-1 mr-3 d-flex justify-content-end align-items-center">

                  <b-button size="sm" v-bind:variant="uploadFile ? 'warning' : 'outline-warning'"
                            @click="()=>uploadFile =!uploadFile">
                    <i class="fas fa-upload"></i> {{ $t("files.uploadFile") }}
                  </b-button>
                </div>

              </b-card-title>
              <div style="height: 550px;">

                  <div class="files-data">
                    <div class="upload-backdrop" v-show="uploadFile === true">
                      <vue-dropzone id="contract-upload" class="h-100" :options="dropzoneOptions" :useCustomSlot=true
                                    :duplicateCheck="true" @vdropzone-complete="uploadComplete"
                                    @vdropzone-success="uploadSuccess">
                        <div class="dropzone-custom-content">
                          <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
                          <div class="subtitle">...or click to select a file from your computer</div>
                        </div>
                      </vue-dropzone>
                    </div>
                    <b-table ref="fileTable" hover striped v-bind:items="(contract.files || [])"
                             v-bind:fields="fileTableFields" @row-clicked="onDownloadFile">
                      <template #cell(time)="timeCol">
                        <div>
                            <span v-if="timeCol.value != null">{{
                                $moment(timeCol.value).format($t("dateFormat.dateTime.format"))
                              }}</span>
                        </div>
                      </template>
                      <template #cell(filename)="fileNameCol">
                        <div>
                          <i v-if="contract.files[fileNameCol.index].downloading"
                             class="fas fa-circle-notch fa-spin"></i>
                          <i v-else v-bind:class="iconByExt(fileNameCol.value)" class="mx-1"
                             style="font-size: 20px"></i>{{ fileNameCol.value }}
                        </div>
                      </template>
                      <template #cell(tag)="data">
                        <div v-if="data.value != null">
                          <i class="mx-1" v-bind:class="[fileTags(data.value).icon]"/>
                          <span>{{ fileTags(data.value).title }}</span>
                        </div>
                      </template>
                    </b-table>
                  </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
    <b-modal id="modal-cancel-contract" v-model="modals.cancelContract.active" centered hide-footer ref="modal"
             v-bind:title="$t('revision.cancelContract')"
             @show="()=>modals.cancelContract.note=''" @ok="onContractCancelled">
      <form ref="form" @submit.stop.prevent="onContractCancelled">
        <b-form-group
            v-bind:label="$t('revision.cancelContractQuestion')"
            label-for="note-input"
        >
          <b-form-textarea
              id="note-input"
              v-model="modals.cancelContract.note"
              required
          ></b-form-textarea>
        </b-form-group>
      </form>
      <div class="text-center">
        <b-button size="sm" variant="outline-secondary" class="mx-1"
                  @click="() => modals.cancelContract.active = false">
          <i class="fas fa-times mr-1"></i> {{ $t("cancel") }}
        </b-button>
        <b-button size="sm" variant="outline-danger" class="mx-1" @click="onContractCancelled">
          <i class="fas fa-save mr-1"></i> {{ $t("save") }}
        </b-button>
      </div>
    </b-modal>
    <b-modal id="modal-reject-contract" v-model="modals.rejectContract.active" centered hide-footer ref="modal"
             v-bind:title="$t('revision.rejectContract')"
             @show="()=>modals.rejectContract.note=''" @ok="onContractRejected">
      <form ref="form" @submit.stop.prevent="onContractRejected">
        <b-form-group
            v-bind:label="$t('revision.rejectContractQuestion')"
            label-for="note-input"
        >
          <b-form-textarea
              id="note-input"
              v-model="modals.rejectContract.note"
              required
          ></b-form-textarea>
        </b-form-group>
      </form>
      <div class="text-center">
        <b-button size="sm" variant="outline-secondary" class="mx-1"
                  @click="() => modals.rejectContract.active = false">
          <i class="fas fa-times mr-1"></i> {{ $t("cancel") }}
        </b-button>
        <b-button size="sm" variant="outline-warning" class="mx-1" @click="onContractRejected">
          <i class="fas fa-save mr-1"></i> {{ $t("save") }}
        </b-button>
      </div>
    </b-modal>
    <b-modal id="modal-approve-contract" size="lg" v-model="modals.approveContract.active" centered hide-footer
             ref="modal" title="Review and sign contract"
             @show="()=>modals.approveContract.signature=null">
      <b-overlay :show="!modals.approveContract.loaded" rounded="sm" opacity="1" variant="white"
                 style="min-height: 500px;margin-top: -20px">
        <div>
          <iframe id="contract-data" ref="contract-data" class="w-100" style="height: 500px"></iframe>
        </div>
        <div class="position-relative" v-if="contract != null">
          <b-row no-gutters>
            <b-col cols="6">
              <div class="mt-2">
                <p><strong>Name:</strong><span class="mx-1">{{
                    contract.landlord.firstname
                  }} {{ contract.landlord.lastname }}</span></p>
                <p><strong>Date:</strong><span class="mx-1">{{ $moment().format("DD/MM/YYYY") }}</span></p>
              </div>
            </b-col>
            <b-col cols="6">
              <i class="fas fa-times float-right text-danger cursor-pointer" v-b-tooltip="`reset signature`"
                 @click="()=>$refs.signaturePad.clearSignature()"></i>
              <VueSignaturePad ref="signaturePad"
                               v-model="modals.approveContract.signature" height="100px"
                               class="mb-2 w-100" style="margin: 0 auto" :options="{}"/>
              <div class="position-absolute text-right"
                   style="top:70px;border-bottom: 2px solid #000;width: 100%;height: 0">
                <i class="fas fa-pen " style="position: absolute; top: -15px; right: 0;"></i>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="text-center">
          <b-button size="sm" variant="outline-secondary" class="mx-1"
                    @click="()=>modals.approveContract.active = false">
            <i class="fas fa-times"></i> {{ $t("cancel") }}
          </b-button>
          <b-button size="sm" variant="outline-success" class="mx-1" @click="onContractSigned()">
            <i class="fas fa-save"></i> {{ $t("revision.approveContractButton") }}
          </b-button>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@/components/app-timeline/AppTimelineItem.vue'
import vue2Dropzone from 'vue2-dropzone'
import HostawayChatComponent from "@/views/contract/HostawayChatComponent";
import PaymentClientTable from "@/views/contract/paymentClientTable";


export default {
  name: "RealEstateRentContractEdit",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    AppCollapse,
    HostawayChatComponent,
    AppCollapseItem,
    // eslint-disable-next-line vue/no-unused-components
    AppTimeline,
    // eslint-disable-next-line vue/no-unused-components
    AppTimelineItem,
    PaymentClientTable,
    vueDropzone: vue2Dropzone,
  },
  data: () => ({
    id: null,
    contract: null,
    permissions: {},
    ksv: {status: "NOT APPLIED YET"},
    contractEdit: {price: 0, provision: null, moveDate: 0, endDate: null, landlord: {}},
    approve: {},
    editableFields: {client: false, contract: false, guarantors: false, tenants: false, guarantor: [], tenant: []},
    property: null,
    client: {},
    uploadFile: false,
    newHistory: {note: "", status: "", visibleToClient: true},
    modals: {
      rejectContract: {
        note: '',
        active: false,
      },
      cancelContract: {
        note: '',
        active: false,
      },
      approveContract: {
        active: false,
        signature: '',
        loaded: false,
      }
    }
  }),
  created() {
    this.id = this.$route.params.id;
    this.refresh();
  },
  computed: {
    ...mapGetters('contract', ['statusData']),
    fileTags() {
      let tags = {
        "unknown": {value: "unknown", title: this.$t("files.tags.unknown"), icon: "fas fa-question"},
        "contract": {value: "contract", title: this.$t("files.tags.contract"), icon: "fas fa-file-contract"},
        "generatedOffer": {value: "offer", title: this.$t("files.tags.generatedOffer"), icon: "fas fa-file-contract"},
        "signedContract": {
          value: "signedContract",
          title: this.$t("files.tags.signedContract"),
          icon: "fas fa-file-signature"
        },
        "generatedContract": {
          value: "generatedContract",
          title: this.$t("files.tags.signedContract"),
          icon: "fas fa-file-signature"
        },
        "clientDocument": {
          value: "clientDocument",
          title: this.$t("files.tags.clientDocument"),
          icon: "fas fa-passport"
        },
        "clientIncomes": {
          value: "clientIncomes",
          title: this.$t("files.tags.clientIncomes"),
          icon: "fas fa-university"
        },
        "Stromvertrag": {
          value: "Stromvertrag",
          title: ("Stromvertrag"),
          icon: "fas fa-file-pdf"
        },
        "Grundbuchauszug": {
          value: "Grundbuchauszug",
          title: ("Grundbuchauszug"),
          icon: "fas fa-file-pdf"
        },
        "Kundenrechnung": {
          value: "Kundenrechnung",
          title: ("Kundenrechnung"),
          icon: "fas fa-file-pdf"
        },
        "Kommissionsrechnung": {
          value: "Kommissionsrechnung",
          title: ("Kommissionsrechnung"),
          icon: "fas fa-file-pdf"
        },
        "Managementrechnung": {
          value: "Managementrechnung",
          title: ("Managementrechnung"),
          icon: "fas fa-file-pdf"
        },
        "Heiz/Gasvertrag": {
          value: "Heiz/Gasvertrag",
          title: ("Heiz/Gasvertrag"),
          icon: "fas fa-file-pdf"
        },
        "Flächenwidmungsplan": {
          value: "Flächenwidmungsplan",
          title: ("Flächenwidmungsplan"),
          icon: "fas fa-file-pdf"
        },
        "Bauplan": {
          value: "Bauplan",
          title: ("Bauplan"),
          icon: "fas fa-file-pdf"
        },
        "Internetrechnung": {
          value: "Internetrechnung",
          title: ("Internetrechnung"),
          icon: "fas fa-file-pdf"
        },
        "Reinigungskosten": {
          value: "Reinigungskosten",
          title: ("Reinigungskosten"),
          icon: "fas fa-file-pdf"
        },
        "Flat-Introduction": {
          value: "Flat-Introduction",
          title: ("Flat-Introduction"),
          icon: "fas fa-file-pdf"
        },
        "CheckIn details": {
          value: "CheckIn details",
          title: ("CheckIn details"),
          icon: "fas fa-file-pdf"
        },
        "General information": {
          value: "General information",
          title: ("General information"),
          icon: "fas fa-file-pdf"
        },
        "Sonstiges": {
          value: "Sonstiges",
          title: ("Sonstiges"),
          icon: "fas fa-file-pdf"
        },
        "document": {value: "document", title: this.$t("files.tags.document"), icon: "fas fa-file-alt"}
      }
      return (tag) => tags[tag] != null ? tags[tag] : tags["unknown"];
    },
    dropzoneOptions() {
      if (this.contract == null || this.contract.id == null)
        return {};
      return {
        url: window.config.apiUrl + 'api/client/real-estate/owner/contract/upload/file/' + this.contract.id,
        headers: this.$api.getClientHeaders(),
        createImageThumbnails: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 256,
        acceptedFiles: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx",
      }
    },
    progress() {
      if (this.contract == null || this.approve == null)
        return 0;
      let keys = Object.keys(this.progressByKey);
      if (keys.length === 0)
        return 100;
      return (Object.values(this.progressByKey).reduce((total, value) => total + value, 0) / keys.length) * 100;
    },
    progressByKey() {
      if (this.contract == null || this.approve == null)
        return {};
      return Object.keys(this.approve).reduce((ans, type) => {
        let keys = Object.keys(this.approve[type]);
        if (keys.length === 0)
          return Object.assign(ans, {[type]: 1})
        let total = keys.filter(k => this.approve[type][k] === true).length;
        return Object.assign(ans, {[type]: (total / keys.length)})
      }, {});
    },
    imageSrc() {
      if (this.property.attachments == null || this.property.attachments[0] == null || this.property.attachments[0].attachmentUrl == null)
        return null
      return (this.property.attachments[0].attachmentUrl.startsWith('http') ? '' : 'https://treasurehome.at') + this.property.attachments[0].attachmentUrl;
    },
    fileTableFields() {
      return [
        {
          key: 'time',
          label: this.$t('files.uploadTime'),
          sortable: true,
        },
        {
          key: 'filename',
          label: this.$t('files.file'),
          sortable: false
        },
        {
          key: 'tag',
          label: this.$t('files.tag'),
          sortable: true,
        },
        {
          key: 'clientNote',
          label: this.$t('files.notes'),
          sortable: false
        },
      ]
    },
  },
  methods: {
    ...mapActions("contract", ["getById", "getDocument",
      "updateContract", "approveContract", "rejectContract", "cancelContract", "getContract", "signContract", "createOrFetchKsv"]),
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    refresh() {
      this.getById(this.id).then(this.onContractReceive, this.onContractRetrieveError);
    },
    onContractReceive(data) {
      console.log("onContractReceive", data)
      this.setContract(data.contract)
      this.permissions = data.permissions;
      this.newHistory.status = this.contract.status;
      this.setContractEdit();
      this.property = data.RealEstate;
      this.client = data.client;

      if (data.Ksv != null)
        this.ksv = data.Ksv;
      if (this.contract.status === "OWNER_APPROVED" || this.contract.status === "REJECTED_OWNER_SIGNED") {
        this.onContractApproved();
      }
    },
    setContract(contract) {
      let $this = this;
      this.contract = contract;
      this.contract.statusHistories = this.contract.statusHistories.sort((a, b) => $this.$moment(b.time, 'YYYY-MM-DD HH:mm:ss').diff($this.$moment(a.time, 'YYYY-MM-DD HH:mm:ss')))
      this.contract.landlord = this.contract.landlord || {};
    },
    onContractRetrieveError(error) {
      console.log(error)
    },
    iconByExt(file) {
      if (file == null) {
        return {fas: true, 'fa-file-alt': true};
      }
      let ext = file.substr(file.lastIndexOf('.') + 1)
      if (ext === 'pdf') {
        return {far: true, 'fa-file-pdf': true}
      }
      if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gift' || ext === 'bmp') {
        return {far: true, ' fa-file-image': true}
      }
      if (ext === 'doc' || ext === 'docx') {
        return {far: true, ' fa-file-word': true}
      }
      if (ext === 'xls' || ext === 'xlsx') {
        return {far: true, ' fa-file-word': true}
      }
      if (ext === 'zip' || ext === 'rar' || ext === '7zip') {
        return {far: true, ' fa-file-archive': true}
      }
      return {fas: true, 'fa-file-alt': true}
    },
    setContractEdit() {
      this.contractEdit.moveDate = this.contract.moveDate
      this.contractEdit.endDate = this.contract.endDate
      this.contractEdit.price = this.contract.price
      this.contractEdit.initialPrice = this.contract.initialPrice
      this.contractEdit.provision = this.contract.provision
      this.contractEdit.income = this.contract.income
      this.contractEdit.bank = this.contract.bank
      this.contractEdit.client = this.contract.client
      if (this.contract.landlord != null)
        this.contractEdit.landlord = this.contract.landlord
    },
    uploadComplete(file, response) {
      console.log('uploadComplete', {file, response})
      let responseData = JSON.parse(file.xhr.responseText);
      let documents = responseData.objects["documents"];
      if (response.data == null || response.data['documents'] == null)
        return;
      console.log('uploadComplete', documents);
      this.contract.files = documents;
    },
    uploadSuccess(file, response) {
      try {
        console.log('uploadSuccess()', {file, response})
        this.$store.dispatch('notifications/success', {
          title: 'Upload document',
          message: 'Document updated successfully'
        }, {root: true});
        if (response.data == null || response.data['documents'] == null)
          return;
        this.contract.files = response.data['documents'];
      } catch (e) {
        console.error('uploadSuccess error', e)
      }
    },
    submitContract() {
      let $this = this;
      this.contractEdit.income = this.contract.income
      this.contractEdit.bank = this.contract.bank
      this.contractEdit.client = this.contract.client
      this.updateContract({id: this.contract.id, contract: this.contractEdit}).then(contract => {
        this.contract.moveDate = contract.moveDate
        this.contract.endDate = contract.endDate
        this.contract.price = contract.price
        this.contract.provision = contract.provision
        if (contract.landlord != null)
          this.contract.landlord = contract.landlord
        $this.setContractEdit()
        if (contract.status !== this.contract.status) {
          this.refresh();
        }
      })
    },
    onDownloadFile(file, index) {
      let $this = this;
      file = this.contract.files[index]
      if (file.downloading === true)
        return;
      file.downloading = true;
      this.$refs.fileTable.refresh();
      this.getDocument({fileId: file.id, contractId: this.contract.id})
          .then(ans => $this.downloadDocument(ans.data, ans.fileName))
          .finally(() => {
            file.downloading = false;
            $this.$refs.fileTable.refresh();
          })
    },
    onContractApprove() {
      let $this = this;
      //TODO: REMOVE IT
      if (this.contract.status === "OWNER_APPROVED") {
        $this.onContractApproved();
        return
      }
      this.approveContract({id: this.contract.id}).then(contract => {
        $this.contract = contract;
        $this.onContractApproved();
      })
    },
    onContractApproved() {
      let $this = this;
      this.modals.approveContract.active = true;
      this.modals.approveContract.loaded = false;
      this.getContract(this.contract.id).then(file => {
        $this.$refs["contract-data"].src = URL.createObjectURL(file.data);
        $this.$refs.signaturePad.resizeCanvas()
        $this.modals.approveContract.loaded = true;
      })
    },
    onContractCancelled() {
      let $this = this;
      this.cancelContract({id: this.contract.id, note: this.modals.cancelContract.note}).then(data => {
        $this.setContract(data.contract);
        $this.permissions = data.permissions;
      })
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-cancel-contract')
      })
    },
    onContractRejected() {
      let $this = this;
      this.rejectContract({id: this.contract.id, note: this.modals.rejectContract.note}).then(data => {
        $this.setContract(data.contract);
        $this.permissions = data.permissions;
      })
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-reject-contract')
      })
    },
    onContractSigned() {
      let $this = this;
      this.modals.approveContract.active = false
      this.signContract({id: this.contract.id, signature: this.getSignature()}).then(data => {
        $this.setContract(data.contract);
        $this.permissions = data.permissions;
      })
    },
    onKsvApply() {
      let $this = this;
      this.createOrFetchKsv({id: this.contract.id, signature: this.getSignature()}).then(data => {
        $this.setContract(data.contract);
        $this.permissions = data.permissions;
      })
    },
    createOrFetchKsvData() {
      let $this = this;
      this.createOrFetchKsv(this.contract.id).then(data =>
          $this.ksv = data.Ksv
      );

    },
    getSignature() {
      let signature = this.$refs.signaturePad.saveSignature();
      return signature.data
    },

  }
}
</script>

<style lang="scss">
.real-estate-rent-contract {
  .card-header {
    padding: 0;

    h4 {
      margin-bottom: 0;
      margin-left: 50px;
    }
  }

  i.icon {
    min-width: 20px;
  }

  .files-data {
    position: relative;
    height: 300px;

    > .upload-backdrop {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 9999;

      .dropzone-custom-content {
        margin-top: 110px
      }
    }
  }
}
</style>
