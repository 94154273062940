<template>
  <div
    role="tablist"
    class="collapse-icon"
    :class="collapseClasses"
  >
    <slot />
  </div>
</template>

<script>

export default {
  props: {
    accordion: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      collapseID: '',
      activeTabs:[]
    }
  },
  provide() {
    return {
      onShow: this.onShow,
      onHide: this.onHide
    }
  },
  computed: {
    collapseClasses() {
      const classes = []

      // Collapse Type
      const collapseVariants = {
        default: 'collapse-default',
        border: 'collapse-border',
        shadow: 'collapse-shadow',
        margin: 'collapse-margin',
      }
      classes.push(collapseVariants[this.type])
      if(this.activeTabs.length > 0)
        classes.push('h-100')
      return classes
    },
  },
  created() {
    this.collapseID = this._uid;
  },
  methods:{
    onShow(el){
      console.log("onShow()")
      this.activeTabs.push(el)
    },
    onHide(el){
      console.log("onHide()")
      this.activeTabs = this.activeTabs.filter(function(ele){
        return ele !== el;
      });
    }
  }
}
</script>
