<template>
  <b-card
      v-bind:id="collapseItemID"
      no-body
      :class="{'open': visible,'rounded':rounded }"
      @mouseenter="collapseOpen"
      @mouseleave="collapseClose"
  >
    <b-card-header

        :class="{'collapsed': !visible}"
        :aria-expanded="visible ? 'true' : 'false'"
        :aria-controls="collapseItemID"
        role="tab"
        data-toggle="collapse"
        class="py-0 "
        @click="updateVisible(!visible)"
    >
      <slot name="header">
        <div class="text-center w-100 pt-1">
          <span class="lead collapse-title">{{ $t(title) }}</span>
        </div>
      </slot>
    </b-card-header>

    <b-collapse
        :id="collapseItemID"
        v-model="visible"
        :accordion="accordion"
        role="tabpanel"
    >
      <b-card-body>
        <slot/>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>


export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    rounded:{
      type:Boolean,
      default: false,
      description: "Card border rounded"
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      collapseItemID: 'collapse-item',
      visible: false,
      openOnHover: this.$parent.hover,
    }
  },
  inject: ['onShow', 'onHide'],
  watch: {
    visible: {
      handler() {
        if (this.visible) this.onShow(this); else this.onHide(this);
      },
      immediate:true,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  created() {
    this.collapseItemID = `collapse-item-${this._uid}`;
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)

    },
    collapseOpen() {
      if (this.openOnHover) {
        this.updateVisible(true);
        //this.$parent.$el.

      }
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>
